import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/styles'

export interface IInfoBoxProps {
  title: string
  children: React.ReactText
  className?: string
}

const useStyles = makeStyles({
  infoBox: {
    padding: 16,
    borderTop: `8px solid #eb5f8e`,
    background: '#efefef',

    '& p:last-child': {
      marginBottom: 0,
    },
  },
  title: {
    fontWeight: 'bold',
  },
})

export const InfoBox = ({ title, children, className }: IInfoBoxProps): JSX.Element => {
  const classes = useStyles()

  return (
    <aside className={clsx(classes.infoBox, className)}>
      <p className={classes.title}>{title}</p>
      <p>{children}</p>
    </aside>
  )
}
