import * as React from 'react'

import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Heading, Input, Text, Wrap, WrapItem } from '@chakra-ui/react'

import { Layout, BodySection, Hero } from '../components/PageLayout'
import SEO from '../components/SEO'
import { PlanBreakdown } from '../components/PlanBreakdown'
import { InfoBox } from '../components/InfoBox'
import { makeStyles } from '@material-ui/styles'

function isValidImsi(imsi: string) {
  if (!imsi.startsWith('234')) return false
  if (imsi.length !== 15) return false

  return true
}

interface OrderState {
  plan: null | 'unlimited'
  imsi: string
  terms: boolean
}

const useStyles = makeStyles({
  ordersUpdate: {
    marginTop: 36,
  },
})

const OrderPage = () => {
  const classes = useStyles()
  const Pages = [Page1, Page2, Page3]

  const [orderState, setOrderState] = React.useState<OrderState>({
    plan: null,
    imsi: '',
    terms: false,
  })
  const [currentPage, setCurrentPage] = React.useState(0)

  const PageComponent = Pages[currentPage]

  const backPage = () => setCurrentPage(p => p - 1)
  const nextPage = () => setCurrentPage(p => p + 1)

  return (
    <Layout>
      <SEO title="Order" description="Purchase your DavMobile plan for use with any existing SIM card of your choice." />
      <Hero title="Order your plan" text="Providing cellular connection throughout David's neighbourhood." />

      <InfoBox className={classes.ordersUpdate} title="Orders update">
        DavMobile is not currently accepting new orders.
      </InfoBox>

      <PageComponent currentPage={currentPage} nextPage={nextPage} backPage={backPage} orderState={orderState} setOrderState={setOrderState} />
    </Layout>
  )
}

export default OrderPage

interface IPageProps {
  currentPage: number
  backPage: () => void
  nextPage: () => void
  orderState: OrderState
  setOrderState: React.Dispatch<React.SetStateAction<OrderState>>
}

const Page1 = ({ nextPage, orderState, setOrderState, ...props }: IPageProps) => {
  if (!orderState.plan) setOrderState(o => ({ ...o, plan: 'unlimited' }))

  return (
    <BodySection>
      <Heading mb={8}>Choose your plan</Heading>

      <PlanBreakdown bordered showOrderBtn={false} />

      <Wrap marginTop={8} as="nav" justify="space-evenly">
        <WrapItem>
          <Button disabled>Back</Button>
        </WrapItem>
        <WrapItem>
          <Button colorScheme="green" onClick={nextPage}>
            Next
          </Button>
        </WrapItem>
      </Wrap>
    </BodySection>
  )
}

const Page2 = ({ nextPage, backPage, orderState, setOrderState, ...props }: IPageProps) => {
  const valid = isValidImsi(orderState.imsi)

  return (
    <BodySection>
      <Heading mb={8}>Enter your details</Heading>

      <label htmlFor="imsi-input">
        <Text mb={2}>IMSI</Text>
        <Input
          isInvalid={!valid}
          id="imsi-input"
          value={orderState.imsi}
          onInput={e => setOrderState(o => ({ ...o, imsi: e.target.value }))}
          placeholder="234151234567890"
        />
      </label>

      <Text fontSize="xs" mt={2}>
        Your International Mobile Subscriber Identity (IMSI) is used to identify your SIM card to mobile networks it wishes to join. This must be
        whitelisted with our core infrastructure to allow you access to our network.
      </Text>

      <Wrap marginTop={8} as="nav" justify="space-evenly">
        <WrapItem>
          <Button onClick={backPage}>Back</Button>
        </WrapItem>
        <WrapItem>
          <Button colorScheme="green" onClick={nextPage} disabled={!valid}>
            Next
          </Button>
        </WrapItem>
      </Wrap>
    </BodySection>
  )
}

const Page3 = ({ nextPage, backPage, orderState, setOrderState, ...props }: IPageProps) => {
  return (
    <BodySection>
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Couldn't complete order
        </AlertTitle>
        <AlertDescription maxWidth="sm">DavMobile is not currently accepting new orders. Please try again later.</AlertDescription>
      </Alert>
    </BodySection>
  )
}
